import { Button, Popconfirm, message, notification } from 'antd'
import axios from 'axios';
import React, { useState } from 'react'
import AddProductModal from '../Products/Add Product/AddProduct';

export default function ProductsActions({ id, seteditLoading, baseUrl, getProducts, suppliers, catagories,Stores }) {
    const [Product, setProduct] = useState();
    const [api, contextHolder] = notification.useNotification();
    const [ismodalOpen, setismodalOpen] = useState(false);
    function openNotificationWithIcon(type, message, description) {
        api[type]({
            message: message,
            description: description,
        });
    }
    
    const cancel = (e) => {
        console.log(e);
        message.error("Click on No");
    };

    async function handleEdit(id) {
        seteditLoading(true);
        await axios
            .get(`${baseUrl}/api/Products/GetOneProduct?ID=${id}`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("KeeperToken"),
                },
            })
            .then((response) => {
                setProduct(response?.data?.product)
                setismodalOpen(true)
                seteditLoading(false)
            })
            .catch((error) => {
                seteditLoading(false);
                console.log(error);
            });
    }
    async function DeleteProduct(ProductId) {
        await axios
            .delete(`${baseUrl}/api/Products/DeleteProduct?Id=${ProductId}`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("KeeperToken"),
                },
            })
            .then((response) => {
                console.log("Product", response);
                openNotificationWithIcon("success", "تم الحذف", "تم حذف المنتج بنجاح");
                getProducts();
            })
            .catch((error) => {
                console.log(error);
            });
    }

   
    return <>
        {contextHolder}
     
            <div className="d-flex justify-content-around align-items-center">
                <Popconfirm
                    placement="topRight"
                    title="حذف هذا المنتج"
                    description="هل انت بالتأكيد تريد حذف هذا المنتج ؟"
                    onConfirm={() => DeleteProduct(id)}
                    onCancel={cancel}
                    okText="نعم"
                    cancelText="لا"
                >
                    <Button type="link" className="" danger>
                        <i className="fa fa-trash  fa-lg" />
                    </Button>
                </Popconfirm>
                <Button
                    type="link"
                    onClick={() => handleEdit(id)}
                >
                    <i className="fa fa-edit fa-lg" />
                </Button>
                {suppliers?.length < 0 && catagories?.length < 0 && Stores?.length < 0 ?
                    '' :
                    <AddProductModal baseUrl={baseUrl} ismodalOpen={ismodalOpen} setismodalOpen={setismodalOpen} product={Product} getProducts={getProducts} categories={catagories} Stores={Stores} Suppliers={suppliers} isEdit={true} />}

                {/* <Button type='link' onClick={() => showmodal(Product.id)}><i className='fa fa-eye fa-lg' /></Button> */}
            </div>
    </>

}

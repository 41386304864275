import React, { useEffect, useState } from 'react'
import { DollarOutlined, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Divider, List, Modal, Spin } from 'antd';
import axios from 'axios';
import CreateOrderModal from './CreateOrderModal';
export default function CasherHome({ baseUrl }) {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [ShowAddModal, setShowAddModal] = useState(false);
    const [ShowendShiftModal, setShowendShiftModal] = useState(false);
    const [ShowShiftModal, setShowShiftModal] = useState(false);
    const [Money, setMoney] = useState();
    const [errorName, setErrorName] = useState(null);
    const [isShiftopen, setisShiftopen] = useState(false);
    const [cash, setcash] = useState();
    const [expensesModal, setExpensesModal] = useState(false);
    const [expenseName, setExpenseName] = useState("");
    const [expensePrice, setExpensePrice] = useState(0);
    const [details, setdetails] = useState();
    const [Showdetails, setShowdetails] = useState();
    const [ischeck, setischeck] = useState(false);
    async function IsThereShiftOpened() {
        setLoading(true)
        await axios.get(`${baseUrl}/api/Shifts/IsThereShiftOpened`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('casherToken'),
            }
        })
            .then((response) => {
                console.log('IsThereShiftOpened res', response);
                setisShiftopen(response.data.shiftOpend)
                setLoading(false)

            })
            .catch((error) => {
                setLoading(false)
                console.log(error);

            })
    }
    async function getCash() {
        setLoading(true)
        await axios.get(`${baseUrl}/api/Shifts/GetCurrentCash`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('casherToken'),
            }
        })
            .then((response) => {
                console.log('cash res', response);
                setcash(response.data)
                setLoading(false)
                getdetails()
            })
            .catch((error) => {
                setLoading(false)
                console.log(error);

            })
    }

    async function openShift() {
        if (Money === null || Money === undefined) {
            setErrorName('من فضلك ادخل النقد  ')
            return
        }
        setLoading(true)
        let data = {
            "cashe": Money
        }
        await axios.post(`${baseUrl}/api/Shifts/OpenShift`, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('casherToken'),
            }
        })
            .then((response) => {
                console.log('IsThereShiftOpened res', response);
                IsThereShiftOpened()
                setLoading(false)
                handleShiftCancel()
            })
            .catch((error) => {
                setLoading(false)
                console.log(error);
                setErrorName(error?.response?.data[0])

            })
    }

    async function closeShift() {
        setLoading(true)
        let data = {}
        await axios.post(`${baseUrl}/api/Shifts/CloseShift`, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('casherToken'),
            }
        })
            .then((response) => {
                console.log('IsThereShiftOpened res', response);
                IsThereShiftOpened()
                setLoading(false)
                handleEndShiftCancel()

            })
            .catch((error) => {
                setLoading(false)
                console.log(error);
                setErrorName(error?.response?.data[0])

            })
    }

    function openEndShift() {
        getCash()
        setShowendShiftModal(true)

    }

    async function handleSendExpenses() {
        const trimmedExpensesName = expenseName.trim();
        if (trimmedExpensesName === "") {
            setErrorName("من فضلك ادخل اسم النثر");
            return;
        } else {
            let data = {
                moneyValue: expensePrice,
                description: expenseName,
            };

            await axios
                .post(`${baseUrl}/api/Expenses/AddExpense`, data, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("casherToken"),
                    },
                })
                .then((response) => {
                    console.log("expenses", response);
                    setExpensesModal(false);
                    setExpenseName("");
                    setExpensePrice(0);
                    setErrorName(null);
                })
                .catch((error) => {
                    setErrorName(error.response.data[0] ? error.response.data[0] : null);
                    if (
                        error.response.data[0] ===
                        "Please Enter number lower than or equal the money in your chashier"
                    ) {
                        setErrorName("لا يوجد مال يكفي في الخزانة");
                    }
                    console.log(error);
                });
        }
    }

    async function getdetails() {
        await axios.get(`${baseUrl}/api/Expenses/GetExpenses`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('casherToken'),
            }
        })
            .then((response) => {
                console.log('expenses res', response);
                setdetails(response.data.expenses)

            })
            .catch((error) => {
                setLoading(false)
                console.log(error);
                setErrorName(error?.response?.data[0])

            })
    }

    useEffect(() => {
        IsThereShiftOpened()

    }, []);


    const handleShiftCancel = () => {
        setMoney(0);
        setShowShiftModal(false);
        setErrorName('')
    };
    const handleEndShiftCancel = () => {
        setShowendShiftModal(false);
        setErrorName('')
    };

    return <>

        {loading ?
            <Spin fullscreen />
            :
            ''
        }
        <div className=' d-flex justify-content-center align-items-center container  ' style={{ minHeight: '90vh' }}>

            <div className='row home  justify-content-center align-items-center gap-5'>
                <div className=' d-flex justify-content-between gap-3 col-8 '>
                    <span className=' d-flex justify-content-start gap-3 '>
                        {isShiftopen ?
                            <>
                                <div
                                    className=" btn btn-danger d-flex  justify-content-center align-items-center gap-2 px-3 shadow-sm py-2 rounded-3 "
                                    onClick={() => {
                                        openEndShift();
                                    }}
                                >
                                    <h4 className="fw-bold">تسليم ورديه</h4>
                                    <i className="fa-solid fa-cash-register fs-4" />
                                </div>
                                <div
                                    className=" btn btn-primary d-flex  justify-content-center align-items-center gap-2 px-3 shadow-sm py-2 rounded-3 "
                                    onClick={() => {
                                        setExpensesModal(true);
                                    }}
                                >
                                    <h4>اضافة نثريات</h4>
                                    <i class="fa-solid fa-file-invoice-dollar fs-4"></i>{" "}
                                </div>
                            </>
                            :
                            <div className=' btn btn-primary d-flex  justify-content-center align-items-center gap-2 px-3 shadow-sm py-2 rounded-3' onClick={() => { setShowShiftModal(true) }}>
                                <h4 className='fw-bold'>استلام ورديه</h4>
                                <i className="fa-solid fa-cash-register fs-4" />
                            </div>
                        }
                    </span>

                </div>
                <div className='d-flex justify-content-center gap-5 '>
                    <div className='card d-flex flex-column justify-content-center align-items-center gap-3 p-0 shadow-sm home-card' onClick={() => { setShowAddModal(true) }}>
                        <PlusCircleOutlined className='fs-1 ' />
                        <h4 className='fw-bold'>انشاء طلب </h4>
                    </div>
                    <div className='card d-flex flex-column justify-content-center align-items-center gap-3 p-0 shadow-sm home-card' onClick={() => {setischeck(true); setShowAddModal(true) }}>
                        <DollarOutlined className='fs-1 ' />
                        <h4 className='fw-bold'>تسعير طلب </h4>
                    </div>
                    <div className='card  d-flex flex-column justify-content-center align-items-center gap-3 p-0 shadow-sm home-card' onClick={() => { navigate('/casher/last-orders') }}>
                        <SearchOutlined className='fs-1 ' />
                        <h4 className='fw-bold'>البحث عن طلب</h4>
                    </div>
                    <div className='card  d-flex flex-column justify-content-center align-items-center gap-3 p-0 shadow-sm home-card' onClick={() => { navigate('/casher/products-info') }}>
                        <i className="fa-solid fa-circle-info fs-1"></i>
                        <h4 className='fw-bold'> استعلام عن منتج</h4>
                    </div>

                  
                </div>
                <div className='d-flex justify-content-center gap-5 '>
                    <div className='card  d-flex flex-column justify-content-center align-items-center gap-3 p-0 shadow-sm home-card' onClick={() => { navigate('/casher/statement') }}>
                        <i className="fa-solid fa-receipt fs-1"></i>
                        <h4 className='fw-bold'>كشف حساب</h4>
                    </div>
                    <div className='card  d-flex flex-column justify-content-center align-items-center gap-3 p-0 shadow-sm home-card' onClick={() => { navigate('/casher/points') }}>
                        <i className="fa-solid fa-circle-dollar-to-slot fs-1"></i>
                        <h4 className='fw-bold'>أدارة النقاط </h4>
                    </div>
                   
                    <div className='card  d-flex flex-column justify-content-center align-items-center gap-3 p-0 shadow-sm home-card' onClick={() => { navigate('/casher/online-orders') }}>
                        <i className="fa-solid fa-store fs-1"></i>
                        <h4 className='fw-bold'>الطلبيات الاونلاين</h4>
                    </div>


                </div>

            </div>

        </div>

        <Modal style={{ paddingBottom: 20 }} open={ShowShiftModal} closeIcon={false} onOk={openShift} onCancel={handleShiftCancel} okText="تأكيد" cancelText='الغاء'>
            <label htmlFor="CategoryNameInput" className='fw-bold'>ادخل النقد الذي تم  استلامه  :</label>
            <input
                type="number"
                min={0}
                accept=''
                inputMode='numeric'
                className="form-control mb-3 mt-2"
                id="CategoryNameInput"
                name="CategoryName"
                value={Money}
                onChange={(e) => setMoney(e.target.value)}
                placeholder='قم بأدخال النقد'
                oninput="validity.valid||(value='');"
            />
            {errorName === null || errorName === "" ? (
                <></>
            ) : (
                <Alert message={errorName} type="error" className='mt-2' />
            )}
        </Modal>
        <Modal style={{ paddingBottom: 20 }} open={ShowendShiftModal} centered closeIcon={false} onOk={closeShift} onCancel={() => { Showdetails ? setShowdetails(false) : handleEndShiftCancel() }} okText="تسليم" cancelText={Showdetails ? 'رجوع' : 'الغاء'}>
            {Showdetails ?
                <List
                    dataSource={details}
                    className='overflow-auto'
                    style={{ maxHeight: '50vh' }}
                    renderItem={(item) => (
                        <List.Item>
                            <List.Item.Meta
                                title={item?.description}
                                description={`${item?.money} جنيه`}
                            />
                        </List.Item>
                    )}
                />
                :
                <>
                    <h5 className=''><span className=''>الأساسي: </span><span className=' '>{cash?.initialCash} جنيه </span></h5>
                    <Divider />
                    <h5 className=''><span className=''>المكسب : </span><span className='text-success '> +{cash?.totalOrderCash} جنيه</span></h5>
                    <Divider />
                    <h5 className=''><span className=''>المصروفات: </span> <span className='text-danger '>-{cash?.totalExpenses} جنيه</span><Button type='link' onClick={() => { setShowdetails(true) }}><i className="fa-regular fa-circle-question fs-5 fa-flip-horizontal"></i></Button></h5>
                    <Divider />
                    <h4 className='fw-bold'><span className=' '> {cash?.totalCash < 0 ? 'عجز' : 'الصافي'} : </span><span className={`${cash?.totalCash < 0 ? 'text-danger' : 'text-success'}`}> {cash?.totalCash} جنيه</span></h4>
                </>}
            {errorName === null || errorName === "" ? (
                <></>
            ) : (
                <Alert message={errorName} type="error" className='mt-2' />
            )}
        </Modal>

        <Modal
            style={{ paddingBottom: 20 }}
            open={expensesModal}
            closeIcon={false}
            onOk={() => handleSendExpenses()}
            onCancel={(e) => setExpensesModal(false)}
            okText="تأكيد"
            cancelText="الغاء"
        >
            <div className="d-flex gap-2 justify-content-evenly align-items-center">
                <div>
                    <label htmlFor="CategoryNameInput" className="fw-bold">
                        ادخل الوصف
                    </label>
                    <input
                        type="text"
                        accept=""
                        inputMode="text"
                        className="form-control mb-3 mt-2"
                        id="expenseNameInput"
                        name="expenseName"
                        value={expenseName}
                        onChange={(e) => setExpenseName(e.target.value)}
                        placeholder="قم بأدخال الوصف"
                        oninput="validity.valid||(value='');"
                    />
                </div>
                <div>
                    <label htmlFor="CategoryNameInput" className="fw-bold">
                        ادخل المبلغ المنفق
                    </label>
                    <input
                        type="number"
                        min={0}
                        accept=""
                        inputMode="numeric"
                        className="form-control mb-3 mt-2"
                        id="CategoryNameInput"
                        name="CategoryName"
                        value={expensePrice}
                        onChange={(e) => setExpensePrice(e.target.value)}
                        placeholder="ادخل المبلغ المنفق"
                        oninput="validity.valid||(value='');"
                    />
                </div>
            </div>
            {errorName === null || errorName === "" ? (
                <></>
            ) : (
                <Alert message={errorName} type="error" className="mt-2" />
            )}
        </Modal>

        <CreateOrderModal baseUrl={baseUrl} open={ShowAddModal} setopen={setShowAddModal} setLoading={setLoading} ischeck={ischeck} />

    </>
}


import { Alert, Divider, InputNumber, Spin, notification, Button, Form, Input, Modal, Select, Tooltip } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CreateOrderModal from "../../CasherHome/CreateOrderModal"

export default function CreateOrder({ baseUrl, ischeck }) {
  const [waiting, setwaiting] = useState();
  const [product, setproduct] = useState();
  const [Loading, setLoading] = useState();
  const [Order, setOrder] = useState();
  const [errorName, setErrorName] = useState(null);
  const [per, setper] = useState(0);
  const [supplierper, setsupplierper] = useState(0);
  const [supplierCode, setSupplierCode] = useState(null);
  const [catogeryCode, setcatogeryCode] = useState(null);
  const [suppliers, setSuppliers] = useState([]);
  const [catagories, setCatagories] = useState([]);
  const [filteredProducts, setFilterProducts] = useState([]);
  const [disabled, setdisabled] = useState(true);
  const [price, setprice] = useState();
  let navigate = useNavigate();
  let { orderId } = useLocation().state ?? { orderId: null };
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [addProductVisible, setaddProductVisible] = useState(false);
  const [ordertVisible, setordertVisible] = useState(false);
  const [addDisVisible, setaddDisVisible] = useState(false);
  const [orderSuppliers, setorderSuppliers] = useState();
  const [Money, setMoney] = useState(0);
  const [amount, setamount] = useState();
  const [flag, setflag] = useState(false);
  const [productdis, setproductdis] = useState([]);
  const [showConfirm, setshowConfirm] = useState(false);
  const [newAmount, setnewAmount] = useState();
  const [pertemp, setpertemp] = useState();
  const [editModel, setEditModel] = useState(false);
  const [productObject, setProductObject] = useState();
  const [amountOfReturn, setAmountOfReturn] = useState(0);
  const [ShowAddModal, setShowAddModal] = useState(false);
  const [isEdit, setisEdit] = useState(false);
  const [orderProductID, setorderProductID] = useState();
  const [storeId, setstoreId] = useState();
  const [form] = Form.useForm();
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("casherToken"),
  }
  useEffect(() => {
    if (orderId === null) {
      navigate("/casher");
    } else {
      getOrderDetails();
      getCatagories();
      getSuppliers();
      getOrderSuppliers();
    }
  }, []);



  useEffect(() => {
    searchCodeID();
  }, [supplierCode, catogeryCode]);

  async function getOrderDetails() {
    setLoading(true);
    await axios
      .get(`${baseUrl}/api/Orders/GetOrderDetails?OrderID=${orderId}`, {
        headers,
      })
      .then((response) => {
        setLoading(false);
        console.log('order', response.data);

        setOrder(response.data.orders);
        setper(response.data.orders.discountPer);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }
  async function OrderDone() {
    let data = {
      orderID: orderId,
      paid: Money,
    };
    await axios
      .post(`${baseUrl}/api/Orders/CompleteOrder`, data, {
        headers,
      })
      .then((res) => {
        PrintOrder() 
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function PrintOrder() {
    window.open(`/casher/blank/${orderId}`, '_blank');
    navigate("/casher");
    
    // navigate("/casher/print-order", { state: { Order: Order } });
  }

  async function addDis() {
    let data = {
      orderId: orderId,
      discount: per,
    };
    await axios
      .post(`${baseUrl}/api/Orders/AddManualDiscount`, data, {
        headers,
      })
      .then((response) => {
        setaddDisVisible(false);
        getOrderDetails();
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }
  async function addSupplierDis() {
    if (supplierCode === null) {
      setErrorName("من فضلك اختر المورد");
      return;
    }
    let data = {
      orderId: orderId,
      supplierID: supplierCode,
      discount: supplierper,
    };
    await axios
      .post(`${baseUrl}/api/Orders/AddDiscountToSupplier`, data, {
        headers,
      })
      .then((response) => {
        handleAddDisCancel();
        getOrderDetails();
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }
  async function addProductsDis() {
    if (productdis.length < 1) {
      setflag(false);
      setper(pertemp);
      return;
    }

    let data = {
      orderId: orderId,
      orderProductIDs: productdis,
      discount: per,
    };
    await axios
      .post(`${baseUrl}/api/Orders/AddDiscountToProduct`, data, {
        headers,
      })
      .then((response) => {
        setflag(false);
        setproductdis([]);
        getOrderDetails();
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  async function cancelOrder() {
    setLoading(true);
    let data = {
      orderID: orderId,
    };
    await axios
      .post(`${baseUrl}/api/Orders/CancelOrder`, data, {
        headers,
      })
      .then((response) => {
        setLoading(false);
        navigate("/casher");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  async function getCatagories() {
    setLoading(true);
    await axios
      .get(`${baseUrl}/api/Categories/GetAll`, {
        headers,
      })
      .then((response) => {
        setLoading(false);

        setCatagories(response.data.categories);
      })
      .catch((error) => {
        setLoading(false);
        console.log("category ", error);
      });
  }

  async function getSuppliers() {
    setLoading(true);
    await axios
      .get(`${baseUrl}/api/Suppliers/GetAll`, {
        headers,
      })
      .then((response) => {
        setLoading(false);
        setSuppliers(response.data.suppliers);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }
  async function getOrderSuppliers() {
    setLoading(true);
    await axios
      .get(`${baseUrl}/api/Orders/GetOrderSuppliers?orderID=${orderId}`, {
        headers,
      })
      .then((response) => {
        setLoading(false);
        setorderSuppliers(response.data.suppliers);
      })
      .catch((error) => {
        setLoading(false);
        console.log("Order suppliers", error);
      });
  }

  async function searchCodeID() {
    const url = `${baseUrl}/api/Products/SearchProduct?${catogeryCode ? `&CategoryID=${catogeryCode}` : ""
      }${supplierCode ? `&SupplierID=${supplierCode}` : ""}`;

    try {
      const response = await axios.get(url, {
        headers,
      });

      if (response.data && response.data.products) {
        setFilterProducts(response.data.products);
      } else {
        console.error("No products found for the given search criteria.");
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function deleteProductFromOrder(productId) {
    setwaiting(true);
    try {
      const response = await axios.delete(
        `${baseUrl}/api/Orders/DeleteProductFromOrder?OrderProductId=${productId}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("casherToken"),
          },
        }
      );

      setLoading(false);
      getOrderDetails(); // Refresh order details after deletion
      getOrderSuppliers();
      handleAddProductCancel();
      setwaiting(false);

      notification.success({
        message: "تم الحذف",
        description: "تم حذف المنتج بنجاح",
      });
    } catch (error) {
      setLoading(false);
      console.error("Error deleting product", error);
      notification.error({
        message: "خطأ",
        description: "حدث خطأ أثناء حذف المنتج",
      });
    }
    setwaiting(false);
  }
  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        isEdit ? editExternalProduct({
          ...values, orderProductId: product.orderProductID, productName: values.ProductName
        }) :
          addExternal({
            ...values,
            orderID: orderId,
            externalProduct: true,
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  async function addProduct() {
    setErrorName(null);
    if (product === undefined) {
      setErrorName("من فضلك اختر المنتج");

      return;
    } else if (amount === undefined || amount <= 0) {
      setErrorName("من فضلك ادخل الكميه");

      return;
    }
    setwaiting(true);

    let data = {
      orderId: orderId,
      price: price,
      productID: product.id,
      amount: amount,
      storeId: storeId
    };
    await axios
      .post(`${baseUrl}/api/Orders/AddProduct`, data, {
        headers,
      })
      .then((response) => {
        getOrderDetails();
        getOrderSuppliers();
        setwaiting(false);

        if (amount > response?.data?.amount) {
          setnewAmount(response?.data?.amount);
          setshowConfirm(true);
          return;
        }
        handleAddProductCancel();
        setwaiting(false);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 500) {
          setErrorName('internal server error')
        }
        else {
          setErrorName(Array.isArray(error?.response?.data) ? error.response.data[0] : 'there is an error please check the console');
        }
      });
  }
  async function editProduct() {
    setErrorName(null);
    if (product === undefined) {
      setErrorName("من فضلك اختر المنتج");

      return;
    } else if (amount === undefined || amount <= 0) {
      setErrorName("من فضلك ادخل الكميه");

      return;
    }
    setwaiting(true);

    let data = {
      orderProductID: orderProductID,
      productID: product.id,
      amount: amount,
      price: price,
    };
    await axios
      .put(`${baseUrl}/api/Orders/EditProductInOrder`, data, {
        headers,
      })
      .then((response) => {
        getOrderDetails();
        getOrderSuppliers();
        setwaiting(false);

        if (amount > response?.data?.amount) {
          setnewAmount(response?.data?.amount);
          setshowConfirm(true);
          return;
        }
        handleAddProductCancel();
        setwaiting(false);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 500) {
          setErrorName('internal server error')
        }
        else {
          setErrorName(Array.isArray(error?.response?.data) ? error.response.data[0] : 'there is an error please check the console');
        }
      });
  }
  async function addExternal(data) {
    await axios
      .post(`${baseUrl}/api/Orders/AddExternalProduct`, data, {
        headers,
      })
      .then((res) => {
        getOrderDetails();
        handleCancel();
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const handleCancel = () => {
    form.resetFields()
    setproduct()
    setisEdit(false)
    setIsModalVisible(false);
  };

  const handleAddProductCancel = () => {
    setaddProductVisible(false);
    setamount();
    setisEdit(false)
    setproduct(null);
    setSupplierCode(null);
    setshowConfirm(false);
    setcatogeryCode(null);
    setstoreId()
    setprice()
    setdisabled(true);
  };
  const handleAddDisCancel = () => {
    setaddDisVisible(false);
    setamount();
    setproduct(null);
    setSupplierCode(null);
    setsupplierper(0);
    setErrorName(null);
  };

  function handleSelectProduct(id) {
    setproduct(filteredProducts.filter((item) => {
      return item.id === id;
    })[0]);
   
    setprice(
      filteredProducts.filter((item) => {
        return item.id === id;
      })[0]?.sellingPrice
    );
    setstoreId(
      filteredProducts.filter((item) => {
        return item.id === id;
      })[0]?.stores[0]?.storeId
    );
  }
  function addproductToarray(id) {
    setproductdis([...productdis, id]);

  }
  function removeproduct(id) {
    setproductdis(
      productdis?.filter((item) => {
        return item !== id;
      })
    );
  }

  function resetEditModel() {
    setEditModel(false);
    setAmountOfReturn(0);
    setErrorName(null);
  }

  async function editAmountHandler() {
    if (amountOfReturn > productObject.amount) {
      setErrorName("كمية المرتجع اكبر من الكمية المطلوبة");
      return;
    }


    await axios
      .post(`${baseUrl}/api/Orders/ReturnProductFromOrder?OrderId=${orderId}&OrderProductId=${productObject.orderProductID}&returnAmount=${amountOfReturn}`, {}, {
        headers,
      })
      .then((response) => {
        getOrderDetails()
        resetEditModel()
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }
  function handleAddok() {
    isEdit ? editProduct() : addProduct()
  }


  const editExternalProduct = async (values) => {
    try {
      console.log('Editing Product:', values);
      await axios.put(`${baseUrl}/api/Orders/EditExternalProduct`, values, { headers });
      getOrderDetails();
      handleCancel();
    } catch (error) {
      console.error('Error editing external product:', error);
    }
  };

  useEffect(() => {
    if (isModalVisible) {
      form.setFieldsValue(product);
    }
  }, [isModalVisible, product, form]);

  return (
    <div className="w-75 mx-auto mt-5">
      {Loading ? <Spin fullscreen /> : ""}
      <div className="d-flex justify-content-around align-items-center gap-3">
        <div className="card col-12 p-4 rounded-2">
          <div className="d-flex justify-content-between align-items-center">

            <div className="d-flex flex-column">
                <h5>العميل: {Order?.customerName}</h5>
                <h5>الفني: {Order?.technincalName}</h5>
              {Order?.customerDescription && (
                <h5>تفاصيل الفاتوره: {Order?.customerDescription}</h5>
              )}
            </div>
            {Order?.existOrder?
            ''
            :
            <Tooltip title='تعديل بيانات الطلبيه'>
              <Button type="link" onClick={() => setShowAddModal(true)}>
                <i className="fa fa-edit text-primary fa-lg"></i>
              </Button>
            </Tooltip>
            }
            {Order ?
              <CreateOrderModal baseUrl={baseUrl} open={ShowAddModal} setopen={setShowAddModal} setLoading={getOrderDetails} isEdit={true} orderID={orderId} Order={Order} />
              :
              null
            }

          </div>
          <Divider className="mt-0" />
          {flag ? (
            <div className="text-center">
              <h5>اختر المنتجات التي تريد اضافه خصم عليها</h5>
            </div>
          ) : (
            <div className="d-flex justify-content-around">
              <button
                className="btn btn-primary rounded-5 px-3"
                onClick={() => setaddProductVisible(true)}
              >
                {" "}
                أضافة منتج{" "}
              </button>
              <button
                className="btn btn-primary rounded-5 px-3"
                onClick={() => setIsModalVisible(true)}
              >
                {" "}
                أضافة منتج خارجي
              </button>
              <button
                className="btn btn-primary rounded-5 px-3"
                onClick={() => setaddDisVisible(true)}
              >
                خصم مورد
              </button>
              <button
                className="btn btn-primary rounded-5 px-3"
                onClick={() => {
                  setflag(true);
                  setpertemp(per);
                  setper(0);
                }}
              >
                خصم منتجات
              </button>
              <button className="btn btn-red rounded-5" onClick={cancelOrder}>
                الغاء الطلب
              </button>
            </div>
          )}
          <div>
            <h5>الطلبيه : </h5>
            <div className="overflow-auto" style={{ maxHeight: "52vh" }}>
              <table className="table table-bordered table-sm custom-table  ">
                <thead>
                  <tr>
                    <th className="text-center ">اسم المنتج</th>
                    <th className="text-center ">الكمية</th>
                    <th className="text-center ">السعر قبل الخصم</th>
                    <th className="text-center ">السعر بعد الخصم</th>
                    <th className="text-center ">الاجمالي قبل الخصم</th>
                    <th className="text-center ">الاجمالي بعد الخصم</th>
                    <th className="text-center ">{flag ? "أختر" : "حذف"} </th>
                  </tr>
                </thead>
                <tbody>
                  {Order?.items?.map((element) => {
                    return (
                      <tr>
                        <td>{element.name}</td>
                        <td>{element.amount} <br /></td>
                        <td>{element.price}</td>
                        <td>{element.newPrice}</td>
                        <td>{element.totalbeforDis}</td>
                        <td>{element.total}</td>
                        <td>
                          {flag ? (
                            <input
                              type="checkbox"
                              className="form-check form-check-input border-secondary m-auto"
                              onChange={(e) =>
                                e.target.checked
                                  ? addproductToarray(element.orderProductID)
                                  : removeproduct(element.orderProductID)
                              }
                            />
                          ) : (
                            <>
                              {element.externalProduct ?
                                <Button
                                  type="link"
                                  onClick={() => {
                                    setproduct({ ...element, ProductName: element.name })
                                    setisEdit(true)
                                    setIsModalVisible(true)
                                  }}
                                  className="text-primary"
                                >
                                  <i className="fa fa-edit fs-5"></i>
                                </Button>
                                :
                                <Button
                                  type="link"
                                  onClick={() => {
                                    setisEdit(true)
                                    let Product = filteredProducts?.filter((product) => product.name === element.name)[0]
                                    setproduct(Product)
                                    setorderProductID(element.orderProductID)
                                    setamount(element.amount)
                                    setstoreId(element?.stores[0]?.storeID)
                                    setprice(element.price)
                                    setaddProductVisible(true)
                                  }}
                                  className="text-primary"
                                >
                                  <i className="fa fa-edit fs-5"></i>
                                </Button>
                              }
                              <Button
                                type="link"
                                onClick={() => {
                                  setEditModel(true);
                                  setProductObject(element);
                                }}
                                className="text-primary"
                              >
                                <i className="fa-solid fa-turn-down fa-rotate-90 fs-5"></i>
                              </Button>

                              <Button
                                type="link"
                                onClick={() =>
                                  deleteProductFromOrder(element.orderProductID)
                                }
                                style={{ color: "red" }}
                              >
                                <i className="fa-sharp fa-solid fa-circle-xmark fs-5"></i>
                              </Button>
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="">
            <h5>الاجمالي : {Order?.totalBeforeDiscount} جنيه</h5>
            {Order?.totalAfterDiscount === Order?.totalBeforeDiscount ? null : (
              <h5>الأجمالي بعد الخصم : {Order?.totalAfterDiscount} جنيه</h5>
            )}
          </div>
          <div className="d-flex justify-content-between gap-3 align-items-center">
            <span>
              {flag ? (
                <label htmlFor="per" className=" fw-semibold">
                  خصم على المنتجات:
                </label>
              ) : (
                <label htmlFor="per" className=" fw-semibold">
                  خصم على الفاتوره:
                </label>
              )}
              <InputNumber
                value={per}
                id="per"
                min={0}
                size="large"
                placeholder="نسبة الخصم"
                max={100}
                formatter={(value) => `${value}%`}
                parser={(value) => value?.replace("%", "")}
                onChange={(e) => setper(e)}
                className="mx-2"
                width={5}
                controls={false}
              />
              <button
                className="btn btn-primary rounded-5 "
                onClick={flag ? addProductsDis : addDis}
              >
                أضافة
              </button>
            </span>
            {flag ? (
              <button
                className="btn btn-danger rounded-5 "
                onClick={() => {
                  setflag(false);
                  setper(pertemp);
                }}
              >
                الغاء
              </button>
            ) : (
              <span>
                {ischeck?
                    <button
                      className="btn btn-primary rounded-5 "
                      onClick={PrintOrder}
                    >
                      حفظ كمسوده <i className="fa-solid fa-inbox"></i>
                    </button>
                :
                    <button
                      className="btn btn-success rounded-5 me-2"
                      onClick={() => {
                        setordertVisible(true);
                      }}
                    >
                      تأكيد الطلب <i className="fa fa-check"></i>
                    </button>
                }
              
              
              </span>
            )}
          </div>
        </div>
      </div>

      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="أَضافه"
        cancelText="الغاء"
        closeIcon={false}
      >
        <Form form={form} layout="vertical" name="userForm">
          <Form.Item
            name="ProductName"
            label="اسم المنتج"
            rules={[{ required: true, message: "Please input the name!" }]}
          >
            <Input size="large" placeholder="الاسم" />
          </Form.Item>

          <div className="row">
            <Form.Item
              name="price"
              className="col-9"
              label="السعر"
              rules={[{ required: true, message: "Please input the selling price!" }]}
            >
              <InputNumber size="large" min={0} style={{ width: "100%" }} placeholder="السعر" />
            </Form.Item>
            <Form.Item
              name="amount"
              label="الكميه"
              className="col-3"
              rules={[{ required: true, message: "Please input the amount!" }]}
            >
              <InputNumber size="large" min={0} style={{ width: "100%" }} placeholder="الكميه" />
            </Form.Item>
          </div>
          <Form.Item
            name="supplierID"
            label="المورد"
          >
            <Select
              placeholder={"المورد"}
              optionFilterProp="label"
              options={suppliers?.map((item) => ({
                label: item.name,
                value: item.id || item.code,
              }))}
              className="custom-dropdown w-100 h-100"
              showSearch
              allowClear
              onClear={() => setSupplierCode("")}
              size="large"
              value={supplierCode}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        open={addProductVisible}
        onOk={handleAddok}
        onCancel={handleAddProductCancel}
        confirmLoading={waiting}
        centered
        closeIcon={false}
        footer={false}
      >
        <h5>تصفيه حسب:</h5>
        <div className="d-flex gap-2">
          <Select
            placeholder={"المورد"}
            optionFilterProp="label" // Use "label" for filtering
            options={suppliers?.map((item) => ({
              label: item.name,
              value: item.id ? item.id : item.code,
            }))}
            className="custom-dropdown w-100 h-100"
            showSearch // Enable search functionality
            onChange={(e) => {
              setSupplierCode(e);
            }} // Handle select change
            allowClear
            onClear={() => setSupplierCode("")}
            size="large"
            value={supplierCode}
          />
          <Select
            placeholder={"الفئة"}
            optionFilterProp="label" // Use "label" for filtering
            options={catagories?.map((item) => ({
              label: item.name,
              value: item.id ? item.id : item.code,
            }))}
            className="custom-dropdown w-100 h-100"
            showSearch // Enable search functionality
            onChange={(e) => {
              setcatogeryCode(e);
            }} // Handle select change
            allowClear
            onClear={() => setcatogeryCode("")}
            size="large"
            value={catogeryCode}
          />
        </div>
        <Divider />
          <Select
            placeholder={"المنتج"}
            optionFilterProp="label"
            options={filteredProducts?.map((product) => ({
              label: `${product.name} (${product.supplierName})`,
              value: product.id,
            }))}
            className=" w-100 mb-2 "
            size="large"
            showSearch
            onChange={(e) => {
              handleSelectProduct(e);
            }}
            allowClear
            value={product?.id}
            onClear={() => setproduct("")}
          />
        <div className="row align-items-center">
          <Select
            placeholder={"المخزن"}
            optionFilterProp="label"
            options={product?.stores?.map((store) => ({
              label: `${store.storeName} `,
              value: store.storeId,
            }))}
            className=" col  px-2"
            size="large"
            showSearch
            onChange={(e) => {
              setstoreId(e);
            }}
            allowClear
            value={storeId}
            onClear={() => setproduct("")}
          />
          <InputNumber
            value={amount}
            onChange={(e) => setamount(e)}
            min={1}
          
            placeholder="الكميه"
            controls={false}
            className="col-3"
            size="large"
          />
        </div>
        <Divider />
        <div className="d-flex justify-content-between align-items-end">
          <span className="d-flex align-items-center ">
            <InputNumber
              value={price}
              onChange={(e) => setprice(e)}
              min={1}
              placeholder="السعر"
              disabled={disabled}
              controls={false}
              className=" mt-2 mx-0  bg-white"
              size="large"
              v
            />
            <Button
              type="link"
              onClick={() => {
                setdisabled(false);
              }}
            >
              <i className="fa fa-edit fa-lg"></i>
            </Button>
          </span>
          <div className="d-flex gap-2">
            <Button onClick={handleAddProductCancel}>الغاء</Button>
            <Button type="primary" onClick={handleAddok} loading={waiting}>
              أضافه
            </Button>
          </div>
        </div>

        {errorName === null || errorName === "" ? (
          <></>
        ) : (
          <Alert message={errorName} type="error" className="" />
        )}
      </Modal>

      <Modal
        visible={addDisVisible}
        onOk={addSupplierDis}
        onCancel={handleAddDisCancel}
        okText="أَضافه"
        cancelText="الغاء"
        closeIcon={false}
        className=""
      >
        <div className="d-flex align-items-center mb-3">
          <label htmlFor="sper" className=" fw-semibold ms-2 col-2">
            المورد:
          </label>

          <Select
            placeholder={"المورد"}
            optionFilterProp="label" // Use "label" for filtering
            options={orderSuppliers?.map((item) => ({
              label: item.name,
              value: item.id ? item.id : item.code,
            }))}
            className="custom-dropdown col-5 h-100"
            showSearch // Enable search functionality
            onChange={(e) => {
              setSupplierCode(e);
            }} // Handle select change
            allowClear
            onClear={() => setSupplierCode("")}
            size="large"
            value={supplierCode}
          />
        </div>
        <div>
          <label htmlFor="sper" className=" fw-semibold ms-2 col-2">
            نسبة الخصم:
          </label>
          <InputNumber
            value={supplierper}
            id="sper"
            min={0}
            size="large"
            placeholder="نسبة الخصم"
            max={100}
            formatter={(value) => `${value}%`}
            parser={(value) => value?.replace("%", "")}
            onChange={(e) => setsupplierper(e)}
            className=" text-center"
            width={5}
            controls={false}
          />
        </div>
        {errorName === null || errorName === "" ? (
          <></>
        ) : (
          <Alert message={errorName} type="error" className="" />
        )}
      </Modal>

      <Modal
        l
        visible={showConfirm}
        onOk={handleAddProductCancel}
        onCancel={() => {
          deleteProductFromOrder(
            Order?.items[Order?.items?.length - 1]?.orderProductID
          );
        }}
        okText="تأكيد"
        cancelText="الغاء"
        confirmLoading={waiting}
        closeIcon={false}
        className="text-center"
        closable={false}
        maskClosable={false}
      >
        <h5>
          لقد قمت بطلب عدد {amount} قطعه والمتوفر في المخزن {newAmount}
        </h5>

        {errorName === null || errorName === "" ? (
          <></>
        ) : (
          <Alert message={errorName} type="error" className="mt-2" />
        )}
      </Modal>

      <Modal
        visible={ordertVisible}
        onOk={OrderDone}
        onCancel={() => {
          setordertVisible(false);
        }}
        okText="تأكيد"
        cancelText="الغاء"
        closeIcon={false}
        className="text-center"
      >
        <label htmlFor="CategoryNameInput" className="fw-bold">
          ادخل النقد الذي تم استلامه :
        </label>
        <input
          type="number"
          min={0}
          accept=""
          inputMode="numeric"
          className="form-control mb-3 mt-2"
          id="CategoryNameInput"
          name="CategoryName"
          value={Money}
          onChange={(e) => setMoney(e.target.value)}
          placeholder="قم بأدخال النقد"
          oninput="validity.valid||(value='');"
        />
        {errorName === null || errorName === "" ? (
          <></>
        ) : (
          <Alert message={errorName} type="error" className="mt-2" />
        )}
      </Modal>

      <Modal
        open={editModel}
        onCancel={() => resetEditModel()}
        onOk={() => editAmountHandler()}
        okText="تأكيد"
        cancelText="الغاء"
        closeIcon={false}
        className="text-center"
      >
        <label htmlFor="CategoryNameInput" className="fw-bold">
          ادخل الكمية التي تريد ارجاعها :{" "}
        </label>
        <input
          type="number"
          min={0}
          accept=""
          inputMode="numeric"
          className="form-control mb-3 mt-2"
          id="EditAmountInput"
          name="EdotAmount"
          value={amountOfReturn}
          onChange={(e) => setAmountOfReturn(e.target.value)}
          placeholder="قم بأدخال النقد"
          oninput="validity.valid||(value='');"
        />
        {errorName === null || errorName === "" ? (
          <></>
        ) : (
          <Alert message={errorName} type="error" className="mt-2" />
        )}
      </Modal>
    </div>
  );
}
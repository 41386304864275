import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CheckProduct = ({ checked, id, baseUrl, key }) => {
    const [ischecked, setischecked] = useState(checked);

    useEffect(() => {
        setischecked(checked);
    }, [checked, key]);

    async function handleChange(value) {
        let data = {
            productId: id,
            review: value
        };

        try {
            const response = await axios.post(`${baseUrl}/api/Products/ReviewProduct`, data, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('KeeperToken'),
                },
            });

            setischecked(value);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="d-flex justify-content-center align-items-center">
            <input type="checkbox" className="form-check form-check-input" checked={ischecked} onChange={(e) => handleChange(e.target.checked)} />
        </div>
    );
};

export default CheckProduct;
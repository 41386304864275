import { Alert, Button, message, Modal, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

export default function CreateOrderModal({ open, setopen, setLoading, baseUrl, isEdit, orderID, Order, ischeck }) {
    let navigate = useNavigate()
    const [customerID, setcustomerID] = useState(Order ? Order?.customerId : 2);
    const [technicianID, settechnicianID] = useState(Order ? Order?.technicalId : 1036);
    const [orderDetails, setorderDetails] = useState(Order? Order?.customerDescription :'');
    const [technicians, settechnicians] = useState();

    const [Customers, setCustomers] = useState();
    const [ShowAddteck, setShowAddteck] = useState(false);

    const [ShowAddcustomar, setShowAddcustomar] = useState(false);

    const [name, setname] = useState();
    const [errorName, setErrorName] = useState(null);

    const [phone, setphone] = useState();

    async function CreateOrder() {

        setLoading(true)
        let data = {
            "customerId": customerID,
            "customerName": Customers?.filter((person) => person.code === customerID)[0]?.name,
            "technicalId": technicianID,
            "technicalName": technicians?.filter((person) => person.code === technicianID)[0]?.name,
            "customerDescription": orderDetails

        }
        console.log(data);

        await axios.post(`${baseUrl}/api/Orders/CreateOrder`, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('casherToken'),
            }
        })
            .then((response) => {
          
                ischeck ? navigate('check', { state: { orderId: response.data.orderID } }) :navigate('add-order', { state: { orderId: response.data.orderID } }) 


            })
            .catch((error) => {
                setLoading(false)
                console.log(error);
                message.error(error?.response?.data[0])
                

            })
    }
    async function editOrder() {
        let data = {
            "orderId": orderID,
            "customerId": customerID,
            "customerName": Customers?.filter((person) => person.code === customerID)[0]?.name,
            "technicalId": technicianID,
            "technicalName": technicians?.filter((person) => person.code === technicianID)[0]?.name,
            "customerDescription": orderDetails
        }
        console.log('data',data);

        await axios.put(`${baseUrl}/api/Orders/EditOrder`, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('casherToken'),
            }
        })
            .then((response) => {
                console.log('categorys res', response);
                console.log('Categories', response.data.categories);
                setLoading()
                setopen(false)

            })
            .catch((error) => {
                console.log(error);

            })
    }

    async function AddTechnician() {
        const trimmedTechnicianName = name.trim();
        if (trimmedTechnicianName === "" || trimmedTechnicianName === null) {
            setErrorName("من فضلك ادخل اسم الفني !");
            return;
        }

        else {
            let data = {
                "name": name,
                "phone": phone
            }


            await axios.post(`${baseUrl}/api/Technicians`, data, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('casherToken'),
                }
            })
                .then((response) => {
                    console.log('Technician', response);
                    setShowAddteck(false)
                    getTechnicians()
                    setname('')
                    setphone('')
                    setErrorName()

                })
                .catch((error) => {
                    console.log(error);

                })
        }
    }

    async function AddCustomers() {
        const trimmedTechnicianName = name.trim();
        if (trimmedTechnicianName === "" || trimmedTechnicianName === null) {
            setErrorName("من فضلك ادخل اسم الفني !");
            return;
        }

        else {
            let data = {
                "name": name,
                "phone": phone
            }


            await axios.post(`${baseUrl}/api/Customers/CreateCustomer`, data, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('casherToken'),
                }
            })
                .then((response) => {
                    console.log('Technician', response);
                    getCustomers()
                    setShowAddcustomar(false)
                    setname('')
                    setphone('')
                    setErrorName()
                })
                .catch((error) => {
                    console.log(error);

                })
        }
    }

    const handleAddCancel = () => {
        setopen(false);
        setErrorName('')
    };

    async function getCustomers() {
        await axios.get(`${baseUrl}/api/Customers/GetAll`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('casherToken'),
            }
        })
            .then((response) => {
                console.log('Technicians res', response);
                setCustomers(response.data.customers)

            })
            .catch((error) => {
                setLoading(false)
                console.log(error);
                setErrorName(error?.response?.data[0])

            })
    }

    useEffect(() => {
        getTechnicians()
        getCustomers()

    }, []);

    async function getTechnicians() {
        await axios.get(`${baseUrl}/api/Technicians/GetAll`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('casherToken'),
            }
        })
            .then((response) => {
                console.log('Technicians res', response);
                settechnicians(response.data.technicians)

            })
            .catch((error) => {
                setLoading(false)
                console.log(error);

            })
    }

    function handeOk() {
        isEdit ? editOrder() : CreateOrder()

    }
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return <>

        <Modal style={{ paddingBottom: 20 }} open={open} closeIcon={false} onOk={handeOk} onCancel={handleAddCancel} okText="تأكيد" cancelText='الغاء'>
            <div>
                <label htmlFor="" className='fw-bold col-3 '>أسم العميل:</label>
                <Select optionFilterProp="children"
                    filterOption={filterOption}
                    showSearch options={Customers?.map((item) => { return { label: item.name, value: item.code } })}
                    placeholder='أسم العميل' className='col-4' onChange={(e) => { setcustomerID(e) }}
                    value={customerID}
                    defaultValue={Order?.customerID}
                />
                <br />

                <label htmlFor="" className='fw-bold col-3 mt-4'>أسم الفني:</label>

                <Select optionFilterProp="children"
                    filterOption={filterOption}
                    showSearch options={technicians?.map((item) => { return { label: item.name, value: item.code } })}
                    placeholder='أسم الفني' className='col-4' onChange={(e) => { settechnicianID(e) }}
                    value={technicianID}
                />
                <br />
                
                <label htmlFor="" className='fw-bold col-3 mt-4'></label>

                <TextArea 
                    
                    placeholder='تفاصيل الفاتوره' 
                    className='col-6 mt-2'
                     onChange={(e) => { setorderDetails(e.target.value) }}
                     autoSize={{ minRows: 3, maxRows: 5 }}

                     
                    value={orderDetails}
                />


            </div>
            <div className='d-flex gap-4 mt-3'>
                <Button type='primary' onClick={() => { setShowAddteck(true) }}>اضف صنايعي جديد</Button>
                <Button type='primary' onClick={() => { setShowAddcustomar(true) }}>اضف عميل جديد</Button>
            </div>
        </Modal>

        <Modal title={<h5 className='font-inter fw-bold'>أضافة فني</h5>} style={{ paddingBottom: 20 }} open={ShowAddteck} closeIcon={false} onOk={AddTechnician} onCancel={() => { setShowAddteck(false); setname(''); setphone('') }} okText="تأكيد" cancelText='الغاء'>
            <div className='my-4'>
                <label htmlFor="TechnicianNameInput" className='fw-bold'>أسم الفني</label>
                <input
                    type="text"
                    className="form-control mb-3 mt-2"
                    id="TechnicianNameInput"
                    name="TechnicianName"
                    value={name}
                    onChange={(e) => setname(e.target.value)}
                    placeholder='أدخل أسم الفني'
                />
                <label htmlFor="phone" className='fw-bold'>رقم الهاتف</label>

                <input
                    type="text"
                    className="form-control mb-3 mt-2"
                    id="phone"
                    name="TechnicianName"
                    value={phone}
                    onChange={(e) => setphone(e.target.value)}
                    placeholder="أدخل رقم الهاتف"
                />
            </div>
            {errorName === null || errorName === "" ? (
                <></>
            ) : (
                <Alert message={errorName} type="error" className='mt-2' />
            )}
        </Modal>

        <Modal title={<h5 className='font-inter fw-bold'>أضافة عميل</h5>} style={{ paddingBottom: 20 }} open={ShowAddcustomar} closeIcon={false} onOk={AddCustomers} onCancel={() => { setShowAddcustomar(false); setname(''); setphone('') }} okText="تأكيد" cancelText='الغاء'>
            <div className='my-4'>
                <label htmlFor="TechnicianNameInput" className='fw-bold'>أسم العميل</label>
                <input
                    type="text"
                    className="form-control mb-3 mt-2"
                    id="TechnicianNameInput"
                    name="TechnicianName"
                    value={name}
                    onChange={(e) => setname(e.target.value)}
                    placeholder='أدخل أسم العميل'
                />
                <label htmlFor="phone" className='fw-bold'>رقم الهاتف</label>

                <input
                    type="text"
                    className="form-control mb-3 mt-2"
                    id="phone"
                    name="TechnicianName"
                    value={phone}
                    onChange={(e) => setphone(e.target.value)}
                    placeholder="أدخل رقم الهاتف"
                />
            </div>
            {errorName === null || errorName === "" ? (
                <></>
            ) : (
                <Alert message={errorName} type="error" className='mt-2' />
            )}
        </Modal>

    </>
}
